<template>
  <div class="grid md:grid-cols-12 grid-cols-10 gap-y-2 mt-2">
    <div class="md:col-span-11 col-span-9">
      <AtomsProgressBar
        v-model="meterProgress[2]"
        line
        :steps="4"
        :step-bg-class="meterProgress[0]"
        :class="{'mr-2':isStrongPassword}"
        class="!h-1 rounded-3xl !bg-gray-350 mt-1.5"
      />
    </div>
    <div class="col-span-1">
      <p class="text-right font-bold text-xs text-dark">
        {{ meterProgress[1] }}
      </p>
    </div>
    <div
      v-if="passwordRequirements.length"
      class="md:col-span-11 col-span-10"
    >
      <p class="font-bold text-xs text-dark">
        Your password must contain:
      </p>
      <ol class="list-disc list-inside columns-2 mt-2">
        <li
          v-for="requirement in passwordRequirements"
          :key="requirement.key"
          class="font-normal text-xs text-dark"
        >
          {{ requirement.value }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script setup>
import memberhubConstants from '~/constants/memberhub.constants'

defineOptions({
  name: 'MoleculesFormPasswordMeter'
})
const props = defineProps({
  schemaObj: {
    type: Object,
    required: true
  }
})
/*
 * Password meter progress / step(s) handler
 * Handles progress bar CSS classes & Step(s) label
 * @returns {(string|number)[]}
 */
const meterProgress = computed(() => {
  let meterStatusClass = 'no-status'
  let meterStatusText = 'Poor'
  let currentMeter = memberhubConstants.PASSWORD_METER.POOR
  if (props.schemaObj?.error?.errorObj?.passwordMeter) {
    switch (props.schemaObj?.error?.errorObj?.passwordMeter) {
      case memberhubConstants.PASSWORD_METER.POOR:// Poor Password
        meterStatusClass = 'bg-alert-error-stroke'
        meterStatusText = 'Poor'
        currentMeter = memberhubConstants.PASSWORD_METER.POOR
        break
      case memberhubConstants.PASSWORD_METER.WEAK:// Weak Password
        meterStatusClass = 'bg-amber-400'
        meterStatusText = 'Weak'
        currentMeter = memberhubConstants.PASSWORD_METER.WEAK
        break
      case memberhubConstants.PASSWORD_METER.GOOD:// Good Password
        meterStatusClass = 'bg-alert-success-stroke'
        meterStatusText = 'Good'
        currentMeter = memberhubConstants.PASSWORD_METER.GOOD
        break
      case memberhubConstants.PASSWORD_METER.STRONG:// Strong Password
        meterStatusClass = 'bg-primary'
        meterStatusText = 'Strong'
        currentMeter = memberhubConstants.PASSWORD_METER.STRONG
        break
      default:
        meterStatusClass = props.schemaObj?.error?.errorObj?.passwordMeter >= memberhubConstants.PASSWORD_METER.STRONG ? 'bg-primary' : 'no-status'
        meterStatusText = props.schemaObj?.error?.errorObj?.passwordMeter >= memberhubConstants.PASSWORD_METER.STRONG ? 'Strong' : 'Poor'
        currentMeter = props.schemaObj?.error?.errorObj?.passwordMeter >= memberhubConstants.PASSWORD_METER.STRONG ? memberhubConstants.PASSWORD_METER.STRONG : memberhubConstants.PASSWORD_METER.POOR
        break
    }
  }
  return [meterStatusClass, meterStatusText, currentMeter]
})

/*
 * Strong password handler for custom CSS conditions
 * @returns {boolean}
 */
const isStrongPassword = computed(() => {
  return props.schemaObj?.error?.errorObj?.passwordMeter >= memberhubConstants.PASSWORD_METER.STRONG
})
/*
 * Password requirements array filtering for respective step(s)
 * @returns {({value: string, key: number}|{value: string, key: number}|{value: string, key: number}|{value: string, key: number}|{value: string, key: number})[]}
 */
const passwordRequirements = computed(() => {
  return [
    { key: memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.MIN_LENGTH, value: '8 characters minimum' },
    { key: memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.UPPERCASE, value: 'an uppercase letter' },
    { key: memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.LOWERCASE, value: 'a lowercase letter' },
    { key: memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.SPECIAL_CHAR, value: 'a special character' },
    { key: memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.NUMBER, value: 'a number' }
  ].filter(item => props.schemaObj?.error?.errorObj?.passwordMeterRequires ? props.schemaObj?.error?.errorObj?.passwordMeterRequires.includes(item.key) : true)
})
</script>

<style scoped>

</style>
